import { defineStore } from 'pinia'

export interface AppConfig {
  CLIENT_URL_SEGMENT_CLIENT_DATA_MAP: Record<
    string,
    {
      clientCode: string
      clientName: string
      status: string
    }
  >
  CLIENT_CODE_CLIENT_NAME_MAP: Record<string, string>
}

export const useAppConfigStore = defineStore('appConfig', {
  state: () => {
    return {
      CLIENT_URL_SEGMENT_CLIENT_DATA_MAP: {} as Record<
        string,
        {
          clientCode: string
          clientName: string
          status: string
        }
      >,
      CLIENT_CODE_CLIENT_NAME_MAP: {} as Record<string, string>,
    }
  },

  actions: {
    loadAppConfigs(appConfig: AppConfig) {
      this.CLIENT_URL_SEGMENT_CLIENT_DATA_MAP = appConfig.CLIENT_URL_SEGMENT_CLIENT_DATA_MAP
      this.CLIENT_CODE_CLIENT_NAME_MAP = appConfig.CLIENT_CODE_CLIENT_NAME_MAP
    },
  },
})
