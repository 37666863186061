export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
  devOnly?: boolean
  headerName?: string
  addSeparator?: boolean
  clientUriPath?: string
  role?: string
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'client-admin',
      displayName: 'menu.admin',
      clientUriPath: 'admin',
      headerName: 'menuHeaders.client-admin',
      meta: {
        icon: 'admin_panel_settings',
      },
      children: [
        // users
        {
          name: 'client-admin-users',
          displayName: 'menu.users',
          clientUriPath: 'admin/users',
        },
        {
          name: 'client-admin-user-groups',
          displayName: 'menu.user-groups',
          clientUriPath: 'admin/user-groups',
        },
        {
          name: 'client-admin-settings',
          displayName: 'menu.admin-settings',
          clientUriPath: 'admin/settings',
        },
        // subscriptions
        {
          name: 'client-admin-subscriptions',
          displayName: 'menu.subscriptions',
          clientUriPath: 'admin/subscriptions',
        },
        {
          name: 'client-billing',
          displayName: 'menu.billing',
          clientUriPath: 'billing',
          meta: {
            icon: 'credit_card',
          },
        },

        {
          name: 'client-pricing-plans',
          displayName: 'menu.pricing-plans',
          clientUriPath: 'pricing-plans',
          meta: {
            icon: 'credit_card',
          },
        },
      ],
      role: 'ADMIN',
    },
    {
      name: 'client-dashboard',
      displayName: 'menu.client-dashboard',
      clientUriPath: 'dashboard',
      meta: {
        icon: 'dashboard',
      },
      role: 'ADMIN',
    },
    {
      name: 'client-reports',
      displayName: 'menu.reports',
      clientUriPath: 'reports',
      meta: {
        icon: 'analytics',
      },
      role: 'ADMIN',
    },
    // PW Admin: clients
    {
      name: 'admin-clients',
      displayName: 'menu.clients',
      meta: {
        // lab organizations icon
        icon: 'business',
      },
      headerName: 'menuHeaders.pw-admin',
    },
    // PW Admin: features
    {
      name: 'admin-features',
      displayName: 'menu.features',
      meta: {
        icon: 'settings',
      },
    },
    // PW Admin: subscription-plans
    {
      name: 'admin-subscription-plans',
      displayName: 'menu.subscription-plans',
      meta: {
        icon: 'credit_card',
      },
      addSeparator: true,
    },
    {
      name: 'test-page',
      displayName: 'menu.test',
      meta: {
        icon: 'code',
      },
      headerName: 'menuHeaders.developer',
    },
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      devOnly: true,
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      name: 'payment-methods',
      displayName: 'menu.payment-methods',
      devOnly: true,
      meta: {
        icon: 'credit_card',
      },
    },
    {
      name: 'users',
      displayName: 'menu.users',
      devOnly: true,
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'projects',
      displayName: 'menu.projects',
      devOnly: true,
      meta: {
        icon: 'folder_shared',
      },
    },
    {
      name: 'auth',
      displayName: 'menu.auth',
      devOnly: true,
      meta: {
        icon: 'login',
      },
      children: [
        {
          name: 'login',
          displayName: 'menu.login',
        },
        {
          name: 'signup',
          displayName: 'menu.signup',
        },
        {
          name: 'recover-password',
          displayName: 'menu.recover-password',
        },
      ],
    },

    {
      name: 'faq',
      displayName: 'menu.faq',
      devOnly: true,
      meta: {
        icon: 'quiz',
      },
    },
    {
      name: '404',
      displayName: 'menu.404',
      devOnly: true,
      meta: {
        icon: 'vuestic-iconset-files',
      },
    },
    {
      name: 'error',
      displayName: 'menu.error',
      devOnly: true,
      meta: {
        icon: 'vuestic-iconset-files',
      },
    },
    // {
    //   name: 'preferences',
    //   displayName: 'menu.preferences',
    //   devOnly: true,
    //   meta: {
    //     icon: 'manage_accounts',
    //   },
    // },
    {
      name: 'settings',
      displayName: 'menu.settings',
      devOnly: true,
      meta: {
        icon: 'settings',
      },
    },
  ] as INavigationRoute[],
}
