import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser'

const scopes = (import.meta.env.VITE_APP_MSAL_ACQUIRE_ACCESS_TOKEN_SCOPES || 'User.Read').split(',')

export const acquireAccessToken = async (instance: PublicClientApplication) => {
  try {
    return await instance.acquireTokenSilent({
      scopes,
    })
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // Prompt the user to log in interactively
      return await instance.acquireTokenPopup({
        scopes,
      })
    } else {
      // Handle other errors
      throw error
    }
  }
}
