import { Router } from 'vue-router'
import { INavigationRoute } from '../components/sidebar/NavigationRoutes'
import { Ref } from 'vue'

export const navigateToRoute = ({ route, router }: { route: INavigationRoute; router: Router }) => {
  if (route.children) {
    // if route has children, do not navigate
    return
  }

  // if iRoute has clientUriPath, navigate to clientUriPath
  if (route.clientUriPath) {
    // log
    console.log('AppSidebar navigate to clientUriPath', route.clientUriPath)

    // extract client segment uri from window location pathname
    const clientSegmentUri = window.location.pathname.split('/')[1]

    // navigate to client uri path
    // window.location.href = currentClient.value
    //   ? `/${clientSegmentUri}/${iRoute.clientUriPath}`
    //   : `/${iRoute.clientUriPath}`
    router.push({
      name: route.name,
      params: { pathMatch: clientSegmentUri },
    })
  }
  // else navigate to route name
  else {
    // log
    console.log('AppSidebar navigate to route name', route.name)

    // navigate to route name
    router.push({ name: route.name })
  }
}
