import { PublicClientApplication } from '@azure/msal-browser'

export const idTokenClaims = (instance: PublicClientApplication) => instance.getActiveAccount()?.idTokenClaims

export const getPWRoles = (idTokenClaims: any): string[] => {
  // return an empty array if idTokenClaims.pwRoles is null
  if (!idTokenClaims?.pwRoles) {
    return []
  }
  return Array.isArray(idTokenClaims.pwRoles) ? idTokenClaims.pwRoles : [idTokenClaims.pwRoles]
}

export const getClientRoles = (
  idTokenClaims: any,
): {
  [clientCode: string]: string[]
} => {
  // return an empty object if idTokenClaims.pwRoles is null
  if (!idTokenClaims.pwRoles) {
    return {}
  }

  // if idTokenClaims.pwRoles is string, put it in an array
  const pwRoles = getPWRoles(idTokenClaims)

  // split the role string by colon
  // construct a map of clientCode to roles
  return pwRoles
    ?.filter((role: string) => role.includes(':'))
    .map((role: string) => role.split(':'))
    .reduce((acc: any, [clientCode, role]: string[]) => {
      if (!acc[clientCode]) {
        acc[clientCode] = []
      }
      acc[clientCode].push(role)
      return acc
    }, {})
}

export const getClientCompanyCodes = (
  idTokenClaims: any,
): {
  [clientCode: string]: string[]
} => {
  // return an empty object if idTokenClaims.clientComCodes is null
  if (!idTokenClaims.clientComCodes) {
    return {}
  }

  // if idTokenClaims.clientComCodes is string, put it in an array
  const clientComCodes = Array.isArray(idTokenClaims.clientComCodes)
    ? idTokenClaims.clientComCodes
    : [idTokenClaims.clientComCodes]

  // split the company codes by colon: clientComCodes
  return clientComCodes
    ?.filter((clientComCode: string) => clientComCode.includes(':'))
    ?.map((clientComCode: string) => clientComCode.split(':'))
    .reduce((acc: any, [clientCode, companyCode]: string[]) => {
      if (!acc[clientCode]) {
        acc[clientCode] = []
      }
      acc[clientCode].push(companyCode)
      return acc
    }, {})
}

export const getClientDoctorCodes = (
  idTokenClaims: any,
): {
  [clientCode: string]: string[]
} => {
  // return an empty object if idTokenClaims.clientDocCodes is null
  if (!idTokenClaims.clientDocCodes) {
    return {}
  }

  // if idTokenClaims.clientDocCodes is string, put it in an array
  const clientDocCodes = Array.isArray(idTokenClaims.clientDocCodes)
    ? idTokenClaims.clientDocCodes
    : [idTokenClaims.clientDocCodes]

  // split the doctor codes by colon: doctorCodes
  return clientDocCodes
    ?.filter((doctorCode: string) => doctorCode.includes(':'))
    ?.map((doctorCode: string) => doctorCode.split(':'))
    .reduce((acc: any, [clientCode, doctorCode]: string[]) => {
      if (!acc[clientCode]) {
        acc[clientCode] = []
      }
      acc[clientCode].push(doctorCode)
      return acc
    }, {})
}

export const getClientUserGroups = (
  idTokenClaims: any,
): {
  [clientCode: string]: string[]
} => {
  // return an empty object if idTokenClaims.clientUserGroups is null
  if (!idTokenClaims.clientUserGroups) {
    return {}
  }

  // for clientUserGroups, prepare array like this:
  //  [`${clientUser1.clientCode}:${group1.groupCode},${group2.groupCode}`,`${clientUser2.clientCode}:${group2.groupCode},${group3.groupCode}`]
  // if idTokenClaims.clientUserGroups is string, put it in an array
  const clientUserGroupsByClientCode = idTokenClaims.clientUserGroups

  // split the user groups by colon for clients, and then by comma for groups
  return clientUserGroupsByClientCode
    ?.filter((clientUserGroup: string) => clientUserGroup.includes(':'))
    ?.map((clientUserGroup: string) => clientUserGroup.split(':'))
    .reduce((acc: any, [clientCode, groupCodes]: string[]) => {
      if (!acc[clientCode]) {
        acc[clientCode] = []
      }
      acc[clientCode].push(...(groupCodes.split(',') ?? []))
      return acc
    }, {})
}
