<template>
  <VaNavbar class="app-layout-navbar py-2 px-0">
    <template #left>
      <div class="left">
        <Transition v-if="isMobile" name="icon-fade" mode="out-in">
          <VaIcon
            color="primary"
            :name="settings.isSidebarMinimized ? 'menu' : 'close'"
            size="20px"
            style="margin-top: 3px"
            @click="settings.isSidebarMinimized = !settings.isSidebarMinimized"
          />
        </Transition>
        <!-- row with two items -->
        <div class="flex flex-row gap-3 items-center align-center">
          <RouterLink to="/" aria-label="Visit home page" class="flex flex-row gap-3 items-center">
            <!-- img with src path: public/pw/pathways logo.png  -->
            <img src="/pw/pathways-logo.png" alt="Pathways Logo" class="logo w-8 h-8" />
            <span class="flex flex-row gap-3 items-center">
              <!-- title: Pathways LIS Management Portal  -->
              <h1 class="flex font-normal text-xl">Pathways Lab Portal</h1>
              <!-- if pwRoles contains "pw:ADMIN", display a VaBadge/VaTag "PW Admin" -->
              <VaBadge v-if="!!pwRoles?.includes('pw:ADMIN')" text="PW Admin"></VaBadge>
            </span>
          </RouterLink>
        </div>
      </div>
    </template>
    <template #right>
      <AppNavbarActions class="app-navbar__actions" :is-mobile="isMobile" />
    </template>
  </VaNavbar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '../../stores/global-store'
import AppNavbarActions from './components/AppNavbarActions.vue'
import { useMsal } from '../../azure/useMsal'
import { getPWRoles } from '../../azure/token-utils'
// import VuesticLogo from '../VuesticLogo.vue'

defineProps({
  isMobile: { type: Boolean, default: false },
})

const GlobalStore = useGlobalStore()

const { settings } = storeToRefs(GlobalStore)

const { instance } = useMsal()

// if requiredRoles is set, check if idTokenClaims.pwRoles contains requiredRoles
const pwRoles = getPWRoles(instance.getActiveAccount()?.idTokenClaims)
</script>

<style lang="scss" scoped>
.va-navbar {
  z-index: 2;

  @media screen and (max-width: 950px) {
    .left {
      width: 100%;
    }

    .app-navbar__actions {
      display: flex;
      justify-content: space-between;
    }
  }
}

.left {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  & > * {
    margin-right: 1rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.icon-fade-enter-active,
.icon-fade-leave-active {
  transition: transform 0.5s ease;
}

.icon-fade-enter,
.icon-fade-leave-to {
  transform: scale(0.5);
}
</style>
