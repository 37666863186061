import { RouteLocationNormalized, Router } from 'vue-router'
import { msalInstance, loginRequest } from '../authConfig'
import { InteractionType, PopupRequest, PublicClientApplication, RedirectRequest } from '@azure/msal-browser'
import { ToastService } from '../services/toast-service'
import { useAppConfigStore } from '../stores/app-config-store'

export function registerGuard(router: Router) {
  router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    if (to.meta.requiresAuth) {
      const request = {
        ...loginRequest,
        redirectStartPage: to.fullPath,
      }
      const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Redirect, request)

      if (!shouldProceed) {
        // set toast message in localStorage
        ToastService.storeToastMessageJson({
          message: 'You have to be authenticated to access this page.',
          color: 'danger',
          position: 'bottom-right',
          closeable: true,
        })
      } else {
        // log to.path
        console.log('Guard to.path: ', to.path)

        const idTokenClaims = msalInstance.getActiveAccount()?.idTokenClaims

        // log idTokenClaims
        console.log('Guard idTokenClaims: ', idTokenClaims)

        // futher check if the idTokenClaims's roles contains the required role(s)
        const requiredRoles: string[] = to.meta.requiredRoles as string[]

        // log requiredRoles
        console.log('Guard requiredRoles: ', requiredRoles)

        if (requiredRoles && requiredRoles.length > 0) {
          if (idTokenClaims) {
            const pwRoles = idTokenClaims['pwRoles'] as string[]

            // log pwRoles
            console.log('Guard pwRoles: ', pwRoles)

            if (!pwRoles || pwRoles.length === 0) {
              // set toast message in localStorage
              ToastService.storeToastMessageJson({
                message: 'You have not been assigned with any role(s) yet to access this page.',
                color: 'danger',
                position: 'bottom-right',
                closeable: true,
              })
              return '/login'
            } else {
              // useAppConfigStore
              const appConfigStore = useAppConfigStore()

              // load app config
              const segmentToClientDataMap = appConfigStore.CLIENT_URL_SEGMENT_CLIENT_DATA_MAP

              const anyRouteRecordIsAdmin = to.matched.some((record) => record.name === 'admin')

              // log anyRouteRecordIsAdmin
              console.log('Guard anyRouteRecordIsAdmin: ', anyRouteRecordIsAdmin)

              if (anyRouteRecordIsAdmin) {
                // filter the roles from pwRoles by those with the correct clientCode that is "pw"
                const userRoles = pwRoles
                  .map((role: string) => role.split(':'))
                  .filter((clientCodeAndRole: string[]) => {
                    const clientCode = clientCodeAndRole[0]
                    return clientCode === 'pw'
                  })
                  .map((clientCodeAndRole: string[]) => clientCodeAndRole[1])

                // log userRoles
                console.log('Guard userRoles: ', userRoles)

                const hasRequiredRoles = requiredRoles.every((role: string) => userRoles.includes(role))
                if (!hasRequiredRoles) {
                  // set toast message in localStorage
                  ToastService.storeToastMessageJson({
                    message: 'You do not have permission to access this page.',
                    color: 'danger',
                    position: 'bottom-right',
                    closeable: true,
                  })
                  return '/login'
                }

                return true
              }

              const anyRouteRecordIsCheckClients = to.matched.some((record) => record.name === 'check-clients')

              // log anyRouteRecordIsCheckClients
              console.log('Guard anyRouteRecordIsCheckClients: ', anyRouteRecordIsCheckClients)

              if (anyRouteRecordIsCheckClients) {
                const segment = to.path.split('/')[1] as string

                // check if the route uri segment is in the clientUriSegments
                // const segment = to.path.split('/')[1] as string

                // filter the roles from pwRoles by those with the correct clientCode
                const userRoles = pwRoles.some((role: string) => role === 'pw:ADMIN') ? ['ADMIN'] : []
                const userRolesToAdd = pwRoles
                  .map((role: string) => role.split(':'))
                  .filter((clientCodeAndRole: string[]) => {
                    const clientCode = clientCodeAndRole[0]
                    return segmentToClientDataMap[segment].clientCode === clientCode
                  })
                  .map((clientCodeAndRole: string[]) => clientCodeAndRole[1])
                for (const role of userRolesToAdd) {
                  if (!userRoles.includes(role)) {
                    userRoles.push(role)
                  }
                }

                // log userRoles
                console.log('Guard userRoles: ', userRoles)

                const hasRequiredRoles = requiredRoles.every((role: string) => userRoles.includes(role))
                if (!hasRequiredRoles) {
                  // set toast message in localStorage
                  ToastService.storeToastMessageJson({
                    message: 'You do not have permission to access this page.',
                    color: 'danger',
                    position: 'bottom-right',
                    closeable: true,
                  })
                  return '/login'
                }

                return true
              }

              // fallback
              return true
            }
          }
        }
      }

      return shouldProceed || '/login'
    }

    return true
  })
}

export async function isAuthenticated(
  instance: PublicClientApplication,
  interactionType: InteractionType,
  loginRequest: PopupRequest | RedirectRequest,
): Promise<boolean> {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
  return instance
    .handleRedirectPromise()
    .then(() => {
      const accounts = instance.getAllAccounts()
      if (accounts.length > 0) {
        return true
      }

      // log interactionType
      console.log('interactionType: ', interactionType)

      // User is not signed in and attempting to access protected route. Sign them in.
      if (interactionType === InteractionType.Popup) {
        return instance
          .loginPopup(loginRequest)
          .then(() => {
            return true
          })
          .catch(() => {
            return false
          })
      } else if (interactionType === InteractionType.Redirect) {
        return instance
          .loginRedirect(loginRequest)
          .then(() => {
            return true
          })
          .catch(() => {
            return false
          })
      }

      return false
    })
    .catch(() => {
      return false
    })
}
